import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'
import QRCode from "react-qr-code";

import ConnectlyLogo from '../assets/img/connectly.png';

import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import {
  Tab,
  Tabs,
  TextField,
  Button,
  Box,
  IconButton
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AddPhotoAlternate, Delete, Save } from '@mui/icons-material';

import {
  BACKEND
} from '../config.js'

function AgentSettings() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    } else {
      
    }
  }, [])

  const [tabValue, setTabValue] = useState(0);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [wallet, setWallet] = useState('');
  const [agentId, setId] = useState('');
  const [link, setLink] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [existingLogo, setLogo] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMailChange = (event) => {
    setMail(event.target.value);
  };

  const handleWalletChange = (event) => {
    setWallet(event.target.value);
  };

  const handleDataChange = async () => {
    try {
      
      let url = encodeURI(BACKEND + "agent/update/" + name + "/" + mail + "/" + wallet)
      console.log(url)
      let save = await axios.post(url)

      
    } catch(e) {
      console.log(e)
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const handleDeleteExistingImage = () => {
    setLogo(null);
    document.getElementById("logoexists").classList.add("hidden");
    document.getElementById("newLogo").classList.remove("hidden");
  };

  const saveImage = () => {
    const formData = new FormData();
    formData.append('image', uploadedImage);
    formData.append('agentId', agentId);
    axios.post(BACKEND + 'agent/agentlogos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      window.location.reload(true);
    }).catch((error) => {
      console.log(error);
    });
  }

  const getData = async () => {
    try {
      const response = await axios.get(BACKEND + "agent");
      if(response.data == "Wrong token. Access denied") {
        window.location.href = "/login";
      }
      setName(response.data.name)
      setMail(response.data.email)
      setWallet(response.data.wallet)
      setId(response.data.id)

      let logo = await axios.get(BACKEND + "agent/logo/" + response.data.id)
      logo = logo.data.split("images/")[1]
      setLogo(BACKEND + logo)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    try {
      const res = await axios.get(BACKEND + "hash/link")
      setLink(res.data)
    } catch(e) {
      console.log(e)
    }
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
    },
    image: {
      width: '50%',
      padding: 10,
    },
    centerImage: {
      alignItems: 'center',
      flexGrow: 1,
    },
  });

  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View >
          <Text style="text-align: center;">Hello World!</Text>
        </View>
        <View>
          <QRCode value={link} />
        </View>
        
        <View style={styles.centerImage}>
          <Image style={styles.image} src={ConnectlyLogo} />
          
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="">
          <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
              <Tab className="pageTitle" label="Persönliche Details" />
              {/* <Tab className="pageTitle" label="Mitgliedschaft" /> */}
          </Tabs>
        </div>
      <div className="">
        {tabValue === 0 && (
            <div className="settingsPanel">
               <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography><h3>Generelle Informationen</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Name"
                    placeholder="Name meiner Firma"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                    required
                    sx={{ m: 1 }}
                  />
                  <TextField
                    label="mail"
                    placeholder="E-Mail meiner Firma"
                    value={mail}
                    onChange={handleMailChange}
                    fullWidth
                    required
                    sx={{ m: 1 }}
                  />
                  <TextField
                    label="Wallet"
                    placeholder="Walletadresse"
                    value={wallet}
                    disabled
                    //onChange={handleWalletChange}
                    fullWidth
                    required
                    sx={{ m: 1 }}
                  />
                  <Button onClick={handleDataChange}>Speichern</Button>
                  <h3>Logo</h3>
                  <div id="logoexists">
                    <div>
                      <img src={existingLogo} alt="Uploaded NFT" style={{ width: '100px' }} />
                      <IconButton onClick={handleDeleteExistingImage}>
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                  <div id="newLogo" className='hidden'>
                    <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload"
                        type="file"
                        onChange={handleImageUpload}
                        sx={{ m: 1 }}
                      />
                      <label htmlFor="image-upload">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<AddPhotoAlternate />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {uploadedImage && (
                        <div>
                          <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded NFT" style={{ width: '100px', height: '100px' }} />
                          <IconButton onClick={handleDeleteImage}>
                            <Delete />
                          </IconButton>
                          <IconButton onClick={saveImage}>
                            <Save />
                          </IconButton>
                        </div>
                      )}
                    </Box>
                  </div>
                  
                  
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{ m: 1 }}
                >
                  <Typography><h3>Onboarding Link</h3></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <h4>Dein persönlicher Onboarding Link</h4>
                  <p>Nutze folgenden Link oder den QR Code, damit deine Kunden sich direkt mit dir auf Connectly verbinden können.</p>
                  <TextField
                    label="link"
                    value={link}
                    disabled
                    //onChange={handleWalletChange}
                    fullWidth
                    required
                    sx={{ m: 1 }}
                  />
                  <h4>Link als QR Code</h4>
                  <QRCode value={link} /><br /><br />
                </AccordionDetails>
              </Accordion>

            </div>
          )}
          {tabValue === 1 && (
            <div>
              <p>tab2</p>
            </div>
          )}

      </div>
    </div>
  );
}

export default AgentSettings;
