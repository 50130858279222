import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import {
  BACKEND
} from './config.js'

import card from './assets/img/card.png'
import metamask from './assets/img/MetaMask.png'

import { LoadingButton } from '@mui/lab';


axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const token = localStorage.getItem('connectlyToken');
    if (origin +"/" == BACKEND) {
      config.headers.authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt, setJwt] = useState(storedJwt || null);
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);

  // check if jwt token is available
  useEffect(() => {
    checkToken()
  }, [])

  async function checkToken() {
    if(jwt) {
      let token = null
      token = localStorage.getItem('connectlyToken');
      if(token != null) {
        if(localStorage.getItem("type") == "2"){
          //window.location.href = "/clientdashboard";
        }
        if(localStorage.getItem("type") == "1") {
          //window.location.href = "/agentdashboard";
        }
      } else {
        console.log("Wrong token. Access denied")
        localStorage.removeItem('connectlyToken');
        localStorage.removeItem('type');
        setJwt(null);
      }
      
    }
  }

  async function connect() {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    )
    const signer = provider.getSigner()
    await provider.send("eth_requestAccounts", [])
    const address = await signer.getAddress() 

    let nonceURL = BACKEND + "nonce/" + address
    let messageToSign = await axios.get(nonceURL)
    
    let signedMessage = await signer.signMessage(messageToSign.data)
    
    let jwtUrl = BACKEND + "login/" + address + "/" + signedMessage
    let getJWT = await axios.get(jwtUrl)

    localStorage.setItem('connectlyToken', getJWT.data);
    setJwt(getJWT.data);
  }

  async function login() {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    )
    const signer = provider.getSigner()
    await provider.send("eth_requestAccounts", [])
    const address = await signer.getAddress() 
    await connect()

    let url = BACKEND + "client/" + address
    let client = await axios.get(url)
    console.log(client.data.wallet)
    if(client.data.wallet == address) {
      localStorage.setItem('type', 2);
    }
    
    url = BACKEND + "agent/" + address
    let agent = await axios.get(url)
    console.log(agent)
    if(agent.data.wallet == address) {
      localStorage.setItem('type', 1);
    }
    console.log(localStorage.getItem("type"))
    if(localStorage.getItem("type") == "2"){
      window.location.href = "/clientdashboard";
    }
    if(localStorage.getItem("type") == "1") {
      window.location.href = "/agentdashboard";
    }
    console.log("type set")
  }

  async function register(step) {
    let start = document.getElementById("start")
    let second = document.getElementById("second")
    let third = document.getElementById("third")
    let forth = document.getElementById("forth")
    /*
    start.classList.add("hidden")
    second.classList.add("hidden")
    third.classList.add("hidden")
    forth.classList.add("hidden")
    */
    console.log(step)
    switch(step) {
      case 1: 
        start.classList.add("hidden")
        second.classList.remove("hidden"); 
        break;
      case 2:
        setLoading(true) 
        let exists = await checkMail()
        if(!exists) {
          let mail = document.getElementById("email").value
          let url = BACKEND + "register/mail/" + mail
          let check = await axios.post(url)
          second.classList.add("hidden")
          third.classList.remove("hidden"); 
        }
        setLoading(false)
        break;
      case 3:
        setLoading(true)  
        let codestate = await sendCode()
        if(codestate) {
          third.classList.add("hidden")
          forth.classList.remove("hidden");
        } 
        setLoading(false) 
        break;
    }
  }

  async function back(step) {
    let start = document.getElementById("start")
    let second = document.getElementById("second")
    let third = document.getElementById("third")
    let forth = document.getElementById("forth")
    
    start.classList.add("hidden")
    second.classList.add("hidden")
    third.classList.add("hidden")
    forth.classList.add("hidden")

    switch(step) {
      case 1: start.classList.remove("hidden"); break;
      case 2: second.classList.remove("hidden"); break;
      case 3: third.classList.remove("hidden"); break;
    }
  }

  async function checkMail() {
    let mail = document.getElementById("email").value
    if(mail == "" || !mail.includes("@")) {
      return false
    } else {
      let url = BACKEND + "register/mail/" + mail
      let check = await axios.get(url)
      //console.log(check)
      if(check.data.length == 0) {
        return false
      } else {
        return true
        
      }
    }
    
  }

  async function sendCode() {
    let code = document.getElementById("emailcode").value
    let mail = document.getElementById("email").value
    if(code == "") {
      return false
    } else {
      let url = BACKEND + "register/code/" + mail + "/" + code
      let check = await axios.get(url)
      if(check.data) {
        return true
      } else {
        return false
        
      }
    }
  }

  async function registerAgent() {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    )
    const signer = provider.getSigner()
    await provider.send("eth_requestAccounts", [])
    const address = await signer.getAddress() 

    if(ethers.utils.isAddress(address)) {
      let name = document.getElementById("business").value
      let mail = document.getElementById("email").value
      let url = BACKEND + "agent/" + address + "/" + name + "/" + mail
      let check = await axios.post(url)
      await connect()

      url = BACKEND + "agent/" + address
      let agent = await axios.get(url)
      if(agent.data.wallet == address) {
        localStorage.setItem('type', 1);
        window.location.href = "/agentdashboard";
      }
    }
    
  }

  function switchAgb() {
    if(document.getElementById("agb").checked) {
        setEnabled(false)
    } else {
        setEnabled(true)
    }
  }

  return (
    <div className="App">
      <div className="" id="start">
        <h2 className="mainTitle">Welcome to <span className="logotext">connectly</span></h2>
        <p className="subTitleExplanation">Melde dich mit deiner Web3 Wallet an, oder registriere dein Unternehmen bei uns.</p>
        <p className="subTitleExplanation">Verbinde eine Web3 Wallet um los zu legen.</p>
        <div className="">
          <img src={card} className=""></img>
        </div>
        <Button variant="contained" className="" id="connectBtn" onClick={login} sx={{ width: 250, }}>Anmelden</Button>
        <div className="or">
          <p className="lineg"><span>OR</span></p>
        </div>
        <Button className="" id="" onClick={() => register(1)} sx={{ width: 250, color: "white", background: "black"}}>Jetzt registrieren</Button>
        <p className="" id="message"></p>
        <p className="" id="signed"></p>
        <p className="" id="result"></p>
        <p className="mt-10">Möchtest du dich als Kunde anmelden/registrieren? Klick bitte <a href="/">hier</a></p>
      </div>
      <div className="hidden" id="second">
        <h2 className="mainTitle">Registriere dein Unternehmen bei Connectly!</h2>
        <p className="subTitleExplanation">Bitte gib deine e-Mail Address und Name des Unternehmens an.</p>
        <div>
          <TextField
            id="email"
            label="E-Mail"
            placeholder="max@muster.ch"
            margin="dense"
            sx={{
              width: 300,
            }}
          />
        </div>
        <div>
          <TextField
            id="business"
            label="Firmenname"
            placeholder="z.B. connectly"
            margin="dense"
            sx={{
              width: 300,
            }}
          />
        </div>
        <div className='agb'>
            <Checkbox id="agb" onClick={switchAgb} className="agbc"/> <p className="agbp">Ich habe die <a href="/terms" target="_blank">Nutzungsbedingungen</a> und die <a href="dataprotection" target="_blank">Datenschutzerklärung</a> gelesen und bin damit einverstanden.</p>
        </div>
        <div className="btnsectoin">
          <Button sx={{ width: 250, color: "white", background: "black"}} id="" onClick={() => back(1)}>Zurück</Button>
          <LoadingButton disabled={enabled} loading={loading} variant="contained" className="" id="contBtn" onClick={() => register(2)} sx={{ width: 250, margin: "10px"}}>Weiter</LoadingButton>
        </div>
      </div>
      <div className="hidden" id="third">
        <h2 className="mainTitle">E-Mail Code eingeben</h2>
        <p className="subTitleExplanation">Wir haben dir zur Verifikation einen Code per e-Mail gesendet.</p>
        <div>
          <TextField
            id="emailcode"
            label="Code"
            placeholder="z.B. 123456"
            margin="dense"
            sx={{
              width: 300,
            }}
          />
        </div> 
        <div className="btnsectoin">
          <Button sx={{ width: 250, color: "white", background: "black"}} id="" onClick={() => back(2)}>Zurück</Button>
          <LoadingButton variant="contained" loading={loading} className="" id="" onClick={() => register(3)} sx={{ width: 250, margin: "10px"}}>Weiter</LoadingButton>
        </div>
      </div>
      <div className="hidden" id="forth">
        <h2 className="mainTitle">Deine Web3 Wallet</h2>
        <p className="subTitleExplanation">Wähle die Web3 Wallet mit der du dich registrieren möchtest.</p>
        <p className="subTitleExplanation"><a href="#">Keine Wallet? Hier findest du eine Anleitung.</a></p>
        <button className="walletBtn" onClick={registerAgent}>
          <img src={metamask} className="metamask"></img>
          <span>MetaMask</span>
        </button>
        <div className="btnsectoin">
          <Button sx={{ width: 250, color: "white", background: "black"}} id="" onClick={() => back(3)}>Zurück</Button>
        </div>
      </div>
    </div>
  );
}

export default App;
