import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  TextField,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  BACKEND
} from '../config.js'
import { upload } from '@testing-library/user-event/dist/upload.js';

const Agentnfts = () => {
  const [nfts, setNfts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabValue2, setTabValue2] = useState(0);
  const [nftDetail, setNftDetail] = useState("");
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [usageDescription, setUsageDescription] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [validfrom, setValidfrom] = useState(new Date().toLocaleDateString("de-DE"));
  const [validuntil, setValiduntil] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString("de-DE"));
  const [nftType, setNftType] = useState('Gutscheinartig');
  const [maxLoadings, setMaxLoadings] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [mintNFT, setmintNFT] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
  }, [])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(BACKEND + "nft/agentnfts");
      if(response.data == "Wrong token. Access denied") {
        window.location.href = "/login";
      }
      let nfts = []

      for(let i = 0; i < response.data.length; i++) {
        // check if lastrun is more than 24 hours ago
        
        let detail = await axios.get(BACKEND + "nft/stats/" + response.data[i].contract + "/" + response.data[i].nftId)

        let item = {
          contract: response.data[i].contract,
          nftId: response.data[i].nftId,
          type: response.data[i].type,
          internalId: response.data[i].internalId,
          agent: response.data[i].agent,
          maxSupply: response.data[i].maxSupply,
          name: response.data[i].name,
          description: response.data[i].description,
          useage_description: response.data[i].useage_description,
          image: response.data[i].image,
          maxLoadings: response.data[i].maxLoadings,
          attributejson: response.data[i].attributejson,
          websiteUrl: response.data[i].websiteURL,
          validfrom: response.data[i].validfrom,
          validuntil: response.data[i].validuntil,
          claimed: detail.data.claimed,
          used: detail.data.used,
          finished: detail.data.finished
        }
        nfts.push(item)
      }

      setNfts(nfts);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredNfts = nfts.filter((nft) =>
    nft.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenDetail = (nft) => {
    setNftDetail(nft)
    setOpenDetail(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabChangeDetail = (event, newValue) => {
    setTabValue2(newValue);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUsageDescriptionChange = (event) => {
    setUsageDescription(event.target.value);
  };

  const handleWebsiteUrlChange = (event) => {
    setWebsiteUrl(event.target.value);
  };

  const handleValidfromChange = (event) => {
    setValidfrom(event.target.value);
  };

  const handleValiduntilChange = (event) => {
    setValiduntil(event.target.value);
  };

  const handleNftTypeChange = (event) => {
    setNftType(event.target.value);
  };

  const handleMaxloadingschange = (event) => {
    setMaxLoadings(event.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
    setDisabled(false)
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const handleAllowPaperNftChange = (event) => {
    setmintNFT(event.target.checked);
  };

  const handleSaveNFT = () => {
    // Make a POST request to save NFT data
    setLoading(true);
    let validF = validfrom
    let validU = validuntil
    
    const nftData = {
      name,
      description,
      usageDescription,
      websiteUrl,
      validF, 
      validU,
      nftType,
      mintNFT,
      uploadedImage,
      validfrom,
      validuntil
    };

    let maxLoads = 1
    if(nftType === "Stempelkarte") {
      maxLoads = maxLoadings
    }
    const formData = new FormData();
    formData.append("name", name)
    formData.append("description", description)
    formData.append("usageDescription", usageDescription)
    formData.append("websiteURL", websiteUrl)
    formData.append("nftType", nftType)
    formData.append("mintNFT", mintNFT)
    formData.append("image", uploadedImage)
    formData.append("maxSupply", 9999)
    formData.append("maxLoadings", maxLoads)
    formData.append("validFrom", validfrom)
    formData.append("validUntil", validuntil)

    // Call the API endpoint and handle the response
    axios.post(BACKEND + "nft/agentnft", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log('NFT saved successfully:', response.data);
        // Optionally, perform any necessary actions after saving the NFT
        // Close the SlidingPane
        fetchData()
        handleClose();
      })
      .catch((error) => {
        console.error('Error saving NFT:', error);
        //console.log(formData)
        // Handle any errors during the save process
      });
      setLoading(false);
      setDisabled(true)
      setNftDetail("")
      window.location.reload(false);
      
  };

  return (
    <div>
      <div className="pageHeader">
        <h2 className="pageTitle">Meine NFTs</h2>

        <div className="actionBtns">
          <Button variant="contained" onClick={handleOpen} sx={{ width: 250, margin: "10px"}}>
            NFT erstellen
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contract</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Image URL</TableCell>
              <TableCell># Betrachtet</TableCell>
              <TableCell># Genutzt</TableCell>
              <TableCell># Verbraucht</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredNfts.map((nft) => (
              <TableRow key={nft.id} onClick={() => handleOpenDetail(nft)}>
                <TableCell>{nft.contract}</TableCell>
                <TableCell>{nft.nftId}</TableCell>
                <TableCell>{nft.name}</TableCell>
                <TableCell>
                  <img src={nft.image} alt={nft.name} style={{ width: '100px', height: '100px' }} />
                </TableCell>
                <TableCell>{nft.claimed}</TableCell>
                <TableCell>{nft.used}</TableCell>
                <TableCell>{nft.finished}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Neuer NFT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Für eine Erläuterung zur Erstellung von NFTs und deren Typen, beachten Sie bitte die Dokumentationen.
          </DialogContentText>
          <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
            <Tab label="Grunddaten" />
            <Tab label="Detaildaten" />
          </Tabs>
          {tabValue === 0 && (
            <div>
              <TextField
                label="Name"
                placeholder="Mein neuer NFT"
                value={name}
                onChange={handleNameChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Beschreibung"
                placeholder="Öffentlicher Text über meinen NFT"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Nutzungsbeschreibung"
                placeholder="Öffentlicher Text zur Nutzung des NFTs"
                value={usageDescription}
                onChange={handleUsageDescriptionChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Website URL"
                placeholder="URL zu Ihrer Kampagnenwebseite"
                value={websiteUrl}
                onChange={handleWebsiteUrlChange}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Gütlig ab"
                placeholder="Gültig ab"
                value={validfrom}
                onChange={handleValidfromChange}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField

                label="Gültig bis"
                placeholder="Gültig bis"
                value={validuntil}
                onChange={handleValiduntilChange}
                fullWidth
                sx={{ m: 1 }}
              />
              <FormControl fullWidth sx={{ m: 1 }}> 
                <Select value={nftType} onChange={handleNftTypeChange}>
                  <MenuItem value="Gutscheinartig">Gutscheinartig</MenuItem>
                  <MenuItem value="Stempelkarte">Stempelkarte</MenuItem>
                </Select>
              </FormControl>
              {nftType==="Stempelkarte" && (
                <TextField
                  label="Maximale Anzahl Stempel"
                  placeholder="10"
                  value={maxLoadings}
                  onChange={handleMaxloadingschange}
                  fullWidth
                  sx={{ m: 1 }}
                />
              )}
            </div>
          )}
          {tabValue === 1 && (
            <div>
              <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageUpload}
                  sx={{ m: 1 }}
                />
                <label htmlFor="image-upload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<AddPhotoAlternate />}
                  >
                    Upload Image
                  </Button>
                </label>
                {uploadedImage && (
                  <div>
                    <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded NFT" style={{ width: '100px', height: '100px' }} />
                    <IconButton onClick={handleDeleteImage}>
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mintNFT}
                    onChange={handleAllowPaperNftChange}
                  />
                }
                label="NFT auf Blockchain speichern"
                sx={{ m: 1 }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {tabValue === 1 && (
            <Button onClick={() => setTabValue(0)}>Zurück</Button>
          )}
          <Button onClick={handleClose}>Abbrechen</Button>
          {tabValue === 0 && (
            <Button onClick={() => setTabValue(1)}>Weiter</Button>
          )}
          {tabValue === 1 && (
            <LoadingButton disabled={disabled} loading={loading} onClick={handleSaveNFT}>Speichern</LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Neuer NFT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Für eine Erläuterung zur Erstellung von NFTs und deren Typen, beachten Sie bitte die Dokumentationen.
          </DialogContentText>
          <Tabs value={tabValue2} onChange={handleTabChangeDetail} indicatorColor="primary">
            <Tab label="Grunddaten" />
            <Tab label="Statistiken" />
          </Tabs>
          {tabValue2 === 0 && (
            <div>
              <TextField
                label="Name"
                placeholder="Mein neuer NFT"
                value={nftDetail.name}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Beschreibung"
                placeholder="Öffentlicher Text über meinen NFT"
                value={nftDetail.description}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Nutzungsbeschreibung"
                placeholder="Öffentlicher Text zur Nutzung des NFTs"
                value={nftDetail.useage_description}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Website URL"
                placeholder="URL zu Ihrer Kampagnenwebseite"
                value={nftDetail.websiteURL}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Gültig ab"
                placeholder="Gültig ab"
                value={nftDetail.validfrom}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Gültig bis"
                placeholder="Gültig bis"
                value={nftDetail.validuntil}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Typ"
                value={ nftDetail.type === 1 ? "Gutscheinartig" : "Stempelkarte"}
                fullWidth
                sx={{ m: 1 }}
              />
              
              {nftDetail.type ==="Stempelkarte" && (
                <TextField
                  label="Maximale Anzahl Stempel"
                  placeholder="10"
                  value={nftDetail.maxLoadings}
                  fullWidth
                  sx={{ m: 1 }}
                />
              )}
              { nftDetail.image !== undefined ? <img src={nftDetail.image} width={250} style={{margin: "20px"}}/> : null }
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetail}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Agentnfts;