import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Button';


import {
  BACKEND
} from '../config.js'

function Clientdashboard() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
  }, [])

  const [agents, setAgents] = useState([]);
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(BACKEND + "agent/myagents");
      let ag = []
      for(let i = 0; i < response.data.length; i++) {
        let item = {
          id: response.data[i].id,
          name: response.data[i].name,
          wallet: response.data[i].wallet,
          mail: response.data[i].email
        }
        ag.push(item)
      }
      console.log(ag)
      setAgents(ag)
      console.log(agents)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="App">
      <div className="pageHeaderBlank">
        <h2 className="pageTitle">Meine Abonnements</h2>
        <div className="">
        <Grid xs={4}>    
          {
            agents.map((agent, i) => (
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <h2>{ agent.id }</h2>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            ))
          }
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Clientdashboard;
