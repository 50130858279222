import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AgentDashboard from './agentpages/agentdashboard';
import AgentNFTs from './agentpages/agentnfts';
import Agentcampaigns from './agentpages/campaigns';
import Agentclients from './agentpages/clients';
import AgentSettings from './agentpages/settings';
import AgentStatistics from './agentpages/statistics';
import ClientDashboard from './clientpages/clientdashboard';
import ClientNfts from './clientpages/mynfts';
import About from './about';
import Nft from './Nft';
import Profile from './clientpages/profile';
import Connect from './clientpages/connect';
import Agents from './clientpages/agents';
import Terms from './Terms';
import Dataprotection from './Dataprotection';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route ,Link, Routes} from "react-router-dom";
import axios from 'axios'

import logo from './assets/img/connectly.png'
import user from './assets/img/user.png'

import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import CampaignIcon from '@mui/icons-material/Campaign';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('connectlyToken');
  config.headers.Authorization =  token;
   
  return config;
});

const Logout = () => {
  let t
  if(localStorage.getItem("type") == "2"){
    t = 2
  }
  if(localStorage.getItem("type") == "1") {
    t = 1
  }
  localStorage.removeItem('connectlyToken');
  localStorage.removeItem('type');
  if(t == 2){
    window.location.href = "/";
  }
  if(t == 1) {
    window.location.href = "/login";
  }
}

root.render(
  <React.StrictMode>
    <div className="headbar" id="headbar">
      <a href="/"><img src={logo} className="logo"></img></a>
      <LogoutIcon className="logout" onClick={() => { Logout() }} />
    </div>
    <div className="" id="main">
      <div className="sidebar bar-block" id="sidebar">
        {
          (localStorage.getItem("type") == undefined && 
          <div className="sidemenu">
            <a href="/" className="bar-item side-button"><div className="sidebtn"><LoginIcon /><span className='menuText'>Anmelden</span></div></a>
            <a href="/about" className="bar-item side-button"><div className="sidebtn"><InfoIcon /><span className='menuText'>About</span></div></a>
          </div>
          )
          ||
          (localStorage.getItem("type") == 1 && 
          <div className="sidemenu">
            <a href="/agentdashboard" className="bar-item side-button"><div className="sidebtn"><DashboardIcon /><span className='menuText'>Dashboard</span></div></a>
            <a href="/clients" className="bar-item side-button"><div className="sidebtn"><PersonIcon /><span className='menuText'>Meine Kunden</span></div></a>
            <a href="/campaigns" className="bar-item side-button"><div className="sidebtn"><CampaignIcon /><span className='menuText'>Kampagnen</span></div></a>
            <a href="/agentnfts" className="bar-item side-button"><div className="sidebtn"><CardGiftcardIcon /><span className='menuText'>Meine NFTs</span></div></a>
            {/* <a href="/statistics" className="bar-item side-button"><InsertChartIcon /><span className='menuText'>Statistiken</span></a>*/}
            <a href="/settings" className="bar-item side-button"><div className="sidebtn"><SettingsIcon /><span className='menuText'>Settings</span></div></a>
            <a href="/about" className="bar-item side-button"><div className="sidebtn"><InfoIcon /><span className="menuText">About</span></div></a>
          </div>
          )
          ||
          (localStorage.getItem("type") == 2 && 
          <div className="sidemenu">
            {/*<a href="/clientdashboard" className="bar-item side-button"><DashboardIcon /><span className='menuText'>Dashboard</span></a>*/}
            <a href="/mynfts" className="bar-item side-button"><div className="sidebtn"><CardGiftcardIcon /><span className='menuText'>Meine NFTs</span></div></a>
            <a href="/agents" className="bar-item side-button"><div className="sidebtn"><ConnectWithoutContactIcon /><span className='menuText'>Partner</span></div></a>
            <a href="/profile" className="bar-item side-button"><div className="sidebtn"><SettingsIcon /><span className='menuText'>Settings</span></div></a>
            <a href="/about" className="bar-item side-button"><div className="sidebtn"><InfoIcon /><span className="menuText">About</span></div></a>
          </div>
          )
        }
      </div>
      <div className="content">
        <Router>
          <Routes> 
              <Route path="/" element={<Connect />} />
              <Route path="/:hash" element={<Connect />} />
              <Route path="/agentdashboard" element={<AgentDashboard />} />
              <Route path="/agentnfts" element={<AgentNFTs />} />
              <Route path="/campaigns" element={<Agentcampaigns />} />
              <Route path="/clients" element={<Agentclients />} />
              <Route path="/settings" element={<AgentSettings />} />
              <Route path="/statistics" element={<AgentStatistics />} />
              <Route path="/clientdashboard" element={<ClientDashboard />} />
              <Route path="/mynfts" element={<ClientNfts />} />
              <Route path="/agents" element={<Agents />} />
              <Route path="/about" element={<About />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<App />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/dataprotection" element={<Dataprotection />} />
              <Route path="/nft/:contract/:nftid" element={<Nft />} />
          </Routes> 
        </Router>
      </div>
    </div>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
