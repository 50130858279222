import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'
import {
  BACKEND
} from '../config.js'

import {  
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid,
  TextField,
  Button
} from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function Agentdashboard() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);

  const [logo, setLogo] = useState(null);
  const [viewed, setViewed] = useState(0);
  const [used, setUsed] = useState(0);
  const [finished, setFinished] = useState(0);
  const [campaigns, setCampaigns] = useState(0);
  const [defaultCampaign, setDefaultCampaign] = useState(0);
  const [clients, setClients] = useState(0);
  const [agentnfts, setAgentnfts] = useState(0);
  const [link, setLink] = useState(null);

  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
    loadData()
  }, [])

  async function loadData() {

    // load stats for nfts via campaignnfts
    const campaignnfturl = BACKEND + "agent/campaignnfts"
    const campaignnftresponse = await axios.get(campaignnfturl)
    if(campaignnftresponse.data.viewed > 0) {
      setViewed(campaignnftresponse.data.viewed)
    } else {
      setViewed(0)
    }
    if(campaignnftresponse.data.used > 0) {
      setUsed(campaignnftresponse.data.used)
    } else {
      setUsed(0)
    }
    if(campaignnftresponse.data.finished > 0) {
      setFinished(campaignnftresponse.data.finished)
    } else {
      setFinished(0)
    }

    // load stats for campaigns via campaigns to check if default campaign is active
    const campaignurl = BACKEND + "campaign/agentstats"
    const campaignresponse = await axios.get(campaignurl)
    setCampaigns(campaignresponse.data.total)
    setDefaultCampaign(campaignresponse.data.default)

    // load stats for users via users to show sum of all users
    const userurl = BACKEND + "agent/clientstats"
    const userresponse = await axios.get(userurl)
    console.log(userresponse.data)
    setClients(userresponse.data.count)

    // load stats for agentnfts for agent to show sum of all agentnfts
    const agentnfturl = BACKEND + "agent/agentnfts"
    const agentnftresponse = await axios.get(agentnfturl)
    setAgentnfts(agentnftresponse.data.total)

    // load agent id
    const url = BACKEND + "agent"
    const response = await axios.get(url)
    try {
      let logo = await axios.get(BACKEND + "agent/logo/" + response.data.id)
      logo = logo.data.split("images/")[1]
      setLogo(BACKEND + logo)
    } catch(e) {

    }
    
    const res = await axios.get(BACKEND + "hash/link")
    setLink(res.data)

    console.log(link)
  }

  function copyLink() {
    let linktocopy = document.getElementById("linkvalue").textContent;
    navigator.clipboard.writeText(linktocopy);
  }

  return (
    <div className="App">
      <div className="pageHeader">
        <h2 className="pageTitle">Deine Connectly Übersicht</h2>
      </div>
      
      <div className="pageContent" >
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{viewed}</h2>
                  <p className="cardDesc">Connects betrachtet</p>
                </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{used}</h2>
                  <p className="cardDesc">NFTs genutzt</p>
                </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{finished}</h2>
                  <p className="cardDesc">NFTs verbraucht</p>
                </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{campaigns}</h2>
                  <p className="cardDesc">Kampagnen erstellt</p>
                </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{clients}</h2>
                  <p className="cardDesc">Anzahl Kunden</p>
                </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
                <CardContent>
                  <h2 className="cardTotal">{agentnfts}</h2>
                  <p className="cardDesc">Anzahl NFTs</p>
                </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div className="pagecontentSecond">
        <Grid container spacing={2}>
          {
              link != null && 
              <Grid item xs={6} md={4}>
                  <Card>
                      <CardContent>
                        <h4 className="cardTotal">Dein Onboarding Link:</h4>
                        <Button
                          onClick={copyLink}
                        >Kopiere Onboarding Link
                        </Button>
                        <span id="linkvalue" className="hidden">{link}</span>
                      </CardContent>
                  </Card>
              </Grid>
            }
            {
              logo == null && 
              <Grid item xs={6} md={4}>
                <a href="/settings">
                  <Card>
                      <CardContent>
                        <h4 className="cardTotal">Kein aktives Logo!</h4>
                        <p className="cardDesc">Logo gesetzt?</p>
                      </CardContent>
                  </Card>
                  
                </a>
              </Grid>
            }
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Agentdashboard;
