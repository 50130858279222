import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import {
  BACKEND
} from '../config.js'

import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tab,
  Tabs,
  Checkbox,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Profile() {

  const [client, setClient] = useState([]);
  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  const [name, setName] = useState('');
  const [wallet, setWallet] = useState('');
  const [mail, setMail] = useState('');
  const [web3disabled, setWeb3disabled] = useState(true);
  const [agents, setAgents] = useState([]);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
    loadClient();
  }, [])



  const loadClient = async () => {
    try {
      const response = await axios.get(BACKEND + "client");
      setName(response.data.name)
      if(response.data.wallet == "undefined") {
        setWallet("")
      } else {
        setWallet(response.data.wallet)
      }
      setMail(response.data.email)

      const agentsresponse = await axios.get(BACKEND + "agent/myagents");
      setAgents(agentsresponse.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }  

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleWalletChange = (event) => {
    setWallet(event.target.value);
  };

  const handleDataChange = async () => {
    try {
      let nt = "name"
      if(name != "") {
        nt = name
      }
      let wt = "wallet"
      if(wallet != "") {
        wt = wallet
      }

      let url = encodeURI(BACKEND + "client/update/" + nt + "/" + wt)
      let save = await axios.post(url)

      
    } catch(e) {
      console.log(e)
    }
  }

  async function loadWallet() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    setWallet(address)
  }

  async function unsubscribeFromAll() {
    try {
      let url = encodeURI(BACKEND + "client/deleteall")
      let save = await axios.post(url)
    } catch(e) {
      console.log(e)
    }
  }

  async function unsubscribeSingle(agent) {
    try {
      let url = encodeURI(BACKEND + "client/deletesingleconnection/" + agent)
      let save = await axios.post(url)
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <div className="App">
      <h2>{mail}'s Profil</h2>
      
      <div className="settingsPanel">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ m: 1 }}
          >
            <Typography><h3>Persönliche Angaben</h3></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              placeholder="Deine E-Mail"
              value={mail}
              //onChange={handleNameChange}
              fullWidth
              disabled
              sx={{ m: 1 }}
            />
            <TextField
              label="Name"
              placeholder="Dein Name"
              value={name}
              onChange={handleNameChange}
              fullWidth
              required
              sx={{ m: 1 }}
            />
            <TextField
              label="Wallet"
              value={wallet}
              onChange={handleWalletChange}
              fullWidth
              required
              disabled={web3disabled}
              sx={{ m: 1 }}
              onClick={loadWallet}
            />
            <Button onClick={handleDataChange}>Speichern</Button>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ m: 1 }}
          >
            <Typography><h3>Meine Connects</h3></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
            {
              agents.map((agent, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Card sx={{ maxWidth: 400 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {agent.name}
                        </Typography>
                        <Button onClick={() => unsubscribeSingle(agent.id)}>Abmelden</Button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            }
            </Grid>
            <Button onClick={unsubscribeFromAll}>Von allen abmelden</Button>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Profile;
