import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import axios from 'axios'
import Searchicon from '../assets/img/search.png'

import {
  Typography,
  TextField,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  BACKEND
} from '../config.js'

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const token = localStorage.getItem('token');
    if (origin +"/" == BACKEND) {
      config.headers.authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


function Clients() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  const [loading, setLoading] = useState(false);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
  }, [])

  const [state, setState] = useState({
    singlePaneOpen: false,
    bulkPaneOpen: false,
    clientDetailopen: false,
    unsubscribePaneOpen: false
  });
  //const [pageination, setPageination] = useState([0])
  const [clientsTable, setClients] = useState([])

  const [detailClient, setDetailClient] = useState({})
  const [openSingle, setOpenSingle] = useState(false);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [fileData, setFileDate] = useState([])
  const [file, setFile] = useState(null)

  useEffect(() => {
    // call api or anything
    loadClients(0)
    
  }, [])

  // client data upload handling
  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const result = [];

    
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(",");
        try {
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLine[j].trim();
        } } catch(e) {
      
        }

        if(obj['"Email Address"'].includes("@")) {
          result.push(obj);
        }
      }
    
  
    return result;
  };

  const handleCSVInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file)
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const csvData = e.target.result;
      const jsonData = convertCSVToJson(csvData);
      console.log(jsonData)
      setFileDate(jsonData);
    };
  
    reader.readAsText(file);
  };

  const handleSaveFileData = (event) => {
    setLoading(true)
    let clients = []
    let amount = 0
    for(let i = 0; i < fileData.length; i++) {
      let mail = fileData[i]['"Email Address"']
      let name = fileData[i]['"First Name"'] + " " + fileData[i]['"Last Name"']
      let wallet = fileData[i]['"Wallet"']

      let item = {
        mail: mail,
        name: name,
        wallet: wallet
      }
      clients.push(item)
      amount++
    }
    let url = encodeURI(BACKEND + "client/clientfile/" + amount)
    axios.post(url, clients)
    
    loadClients()
    handleCloseMultiple()
    
    setLoading(false)
  }

  const handleDeleteFile = (event) => {
    setFile(null)
    setFileDate({})
  }

  const handleOpenSingle = () => {
    setOpenSingle(true);
  };

  const handleCloseSingle = () => {
    setOpenSingle(false);
  };

  const handleOpenMultiple = () => {
    setOpenMultiple(true);
  };

  const handleCloseMultiple = () => {
    handleDeleteFile()
    setOpenMultiple(false);
  };

  const handleOpenDetail = (client) => {
    clientDetail(client)
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };


  async function loadClients(page) {
    let url = BACKEND + "client/allclients/" + page
    let data = await axios.get(url)
    if(data.data == "Wrong token. Access denied") {
      window.location.href = "/login";
    }
    
    let clients = []
    for(let i = 0; i < data.data.length; i++) {
      let cwallet = "Nicht vorhanden"
      if(data.data[i].wallet != "undefined") {
        cwallet = data.data[i].wallet
      }
      let item = {
        id: i,
        name: data.data[i].name,
        wallet: cwallet,
        email: data.data[i].email,
        clientId: data.data[i].clientId,
        agentId: data.data[i].agentId
      }
      clients.push(item)
    }
    setClients(clients)
  }

  async function addClient() {
    setLoading(true)
    let state = true
    // check if mail is mail
    let mail = document.getElementById("newclientmail").value
    if(!mail.includes("@") || !mail.includes(".")) {
      document.getElementById("newclientmailerror").classList.remove("hidden")
      state = false
    } else {
      document.getElementById("newclientmailerror").classList.add("hidden")
    }

    // check if name is given
    let name = document.getElementById("newclientname").value
    if(!name) {
      document.getElementById("newclientnamerror").classList.remove("hidden")
      state = false
    } else {
      document.getElementById("newclientnamerror").classList.add("hidden")
    }

    // check if wallet address is valid
    let wallet = document.getElementById("newclientwallet").value
    if(wallet) {
      if(!ethers.utils.isAddress(wallet)) {
        state = false
        document.getElementById("newclientwalleterror").classList.remove("hidden")
      } else {
        document.getElementById("newclientwalleterror").classList.add("hidden")
      }
    } 

    if(state) {
      let url = encodeURI(BACKEND + "client/" + name + "/" + mail + "/" + wallet)
      let check = await axios.post(url)
      if(check.data = "Gespeichert") {
        loadClients()
        handleCloseSingle()
      } else {
        
      }
    }
    setLoading(false)

  }
  
  function clientDetail(id) {
    setState({ clientDetailopen: true })
    let client = {
      name: clientsTable[id].name,
      email: clientsTable[id].email,
      wallet: clientsTable[id].wallet,
      clientId: clientsTable[id].clientId,
      agentId: clientsTable[id].agentId
    }
    setDetailClient(client)
  }

  async function editClient() {
    
  }

  async function removeClient() {
    let url = BACKEND + "client/remove/" + detailClient.clientId
    let check = await axios.post(url)
    loadClients()
    handleCloseDetail()
  }

  return (
    <div className="App">
      <div className="pageHeader">
        <h2 className="pageTitle">Kunden</h2>
        <div className="actionBtns">
          <Button className="" id="" onClick={handleOpenMultiple} sx={{ width: 250, color: "white", background: "black"}}>Dateiupload</Button>
          <Button variant="contained" className="" id="" onClick={handleOpenSingle} sx={{ width: 250, margin: "10px"}}>Kunde hinzufügen</Button>
        </div>
      </div>
      <div className="contentContainer">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Mail</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right">Wallet</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {clientsTable.map((client) => (
                <TableRow
                  key={client.email}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => handleOpenDetail(client.id)}
                >
                  <TableCell component="th" scope="client">
                    {client.email}
                  </TableCell>
                  <TableCell align="left">{client.name}</TableCell>
                  <TableCell align="right">{client.wallet}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={openSingle}
        onClose={handleCloseSingle}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Neuer Kunde</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''>Gib die Email, Namen und Walletadresse des Kunden an.</p>
          </DialogContentText>
            <TextField
              id="newclientmail"
              label="E-Mail"
              placeholder="max@muster.ch"
              margin="dense"
              fullWidth
            />
            <p className="errorText hidden" id="newclientmailerror">Ungültige E-Mail Adresse</p>
              <TextField
              id="newclientname"
              label="Name"
              placeholder="Max Muster"
              margin="dense"
              fullWidth
            />
            <p className="errorText hidden" id="newclientnamerror">Name muss angegeben werden</p>
              <TextField
              id="newclientwallet"
              label="Walletaddresse"
              placeholder="0x00..."
              margin="dense"
              fullWidth
            />
            <p className="errorText hidden" id="newclientwalleterror">Ungültige Walletadresse</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSingle}>Abbrechen</Button>
            <LoadingButton loading={loading} onClick={addClient}>Speichern</LoadingButton>
            
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMultiple}
        onClose={handleCloseMultiple}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Mehrere Kunden</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''>Dateiupload für die Erfassung mehrere Kunden</p>
          </DialogContentText>
            <div>
              <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                { !file && (
                  <div>
                    <input
                      accept=".csv"
                      style={{ display: 'none' }}
                      id="csv-upload"
                      type="file"
                      onChange={handleCSVInputChange}
                      sx={{ m: 1 }}
                    />
                    <label htmlFor="csv-upload">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<FileOpenIcon />}
                      >
                        Upload CSV Kundenliste
                      </Button>
                    </label>
                  </div>
                )}

              </Box>
            </div>
            {
              file && (
                <div className="">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Mail</TableCell>
                          <TableCell>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {fileData.map((client) => (
                        <TableRow key={client['"Email Address"']}>
                          <TableCell>{client['"Email Address"']}</TableCell>
                          <TableCell>{client['"First Name"']} {client['"Last Name"']}</TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMultiple}>Abbrechen</Button>
            {file && (
              <div>
                <Button onClick={handleDeleteFile}>Datei Löschen</Button>
                <LoadingButton onClick={handleSaveFileData}>Kunden speichern</LoadingButton>
              </div>
            )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Kundendetail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''></p>
          </DialogContentText>
          <div className='sidediv'>
              <TextField
                id="detailEmail"
                label="Email"
                margin="dense"
                fullWidth
                value={detailClient.email}
                required
              />
          </div>
          <p className="errorText hidden" id="detailEmailError">Ungültige E-Mail Adresse</p>
          <div className='sidediv'>
              <TextField
                id="detailName2"
                label="Name"
                margin="dense"
                fullWidth
                value={detailClient.name}
                required
              />
          </div>
          <p className="errorText hidden" id="detailNameError">Name muss angegeben werden</p>
          <div className='sidediv'>
              <TextField
                id="detailWallet"
                label="Wallet"
                margin="dense"
                fullWidth
                value={detailClient.wallet}
                
              />
          </div>
          <p className="errorText hidden" id="detailWalletError">Ungültige Walletadresse</p>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={removeClient}>Kunde entfernen</Button>
            <Button onClick={handleCloseDetail}>Abbrechen</Button>
            <LoadingButton loading={loading} onClick={editClient}>Speichern</LoadingButton>
            
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default Clients;
