import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import LoopIcon from '@mui/icons-material/Loop';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { LoadingButton } from '@mui/lab';
import ShareIcon from '@mui/icons-material/Share';

import {  
  Typography,
  TextField,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Tooltip
} from '@mui/material';

import { CardActionArea } from '@mui/material';

import {
  BACKEND
} from '../config.js'

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const token = localStorage.getItem('token');
    if (origin +"/" == BACKEND) {
      config.headers.authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function Campaigns() {

  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  const [loading, setLoading] = useState(false);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/login";
    }
  }, [])

  const [state, setState] = useState({
    createCampaignOpen: false,
    campaignDetailOpen: false
  });

  const [campaignTable, setCampaigns] = useState([])

  const [campaignDetail, setcampaignDetail] = useState({})
  const [openSingle, setOpenSingle] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openExistingNFT, setOpenExistingNFT] = useState(false);
  const [openNewNFT, setOpenNewNFT] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [linkedNfts, setLinkedNfts] = useState([]);
  const [nfttabValue, setNftTabValue] = useState(0);

  const [nftDetail, setNftDetail] = useState("");
  const [nftname, setName] = useState('');
  const [description, setDescription] = useState('');
  const [usageDescription, setUsageDescription] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [validfrom, setValidfrom] = useState(new Date().toLocaleDateString("de-DE"));
  const [validuntil, setValiduntil] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString("de-DE"));
  const [nftType, setNftType] = useState('Gutscheinartig');
  const [maxLoadings, setMaxLoadings] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [mintNFT, setmintNFT] = useState(false);

  useEffect(() => {
    // call api or anything
    loadCampaigns(0)
    
  }, [])

  const handleOpenSingle = () => {
    
    setOpenSingle(true);
  };

  const handleCloseSingle = () => {
    setOpenSingle(false);
  };

  const handleOpenDetail = (client) => {
    savecampaignDetail(client)
    setLinkedNfts([])
    loadLinkedNFTs(client)
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleOpenExistingNFT = (client) => {
    setOpenExistingNFT(true);
  };

  const handleCloseExistingNFT = () => {
    if(openSingle) {
      handleCloseSingle()
    }
    setOpenExistingNFT(false);
  };

  const handleOpenNewNFT = (client) => {
    setOpenNewNFT(true);
  };

  const handleCloseNewNFT = () => {
    setOpenNewNFT(false);
  };

  const handleNftTabChange = (event, newValue) => {
    setNftTabValue(newValue);
  };

  async function loadCampaigns() {
    let pageination = 0
    let url = BACKEND + "campaign/campaigns/" + pageination
    let data = await axios.get(url)
    
    if(data.data == "Wrong token. Access denied") {
      window.location.href = "/login";
    }

    let campaigns = []
    let item = {}
    for(let i = 0; i < data.data.length; i++) {
      // check if lastrun is more than 24 hours ago
      let runable = false
      var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      var lastrundate = new Date(data.data[i].lastrun)

      let detail = await axios.get(BACKEND + "campaign/stats/" + data.data[i].id)

      if(lastrundate < yesterday) {
        runable = true
      }

      item = {
        id: i,
        campid: data.data[i].id,
        name: data.data[i].name,
        description: data.data[i].description,
        date: data.data[i].created,
        default: data.data[i].defaultCampagin,
        nftsdistributed: data.data[i].nftsdistributed,
        ready: data.data[i].ready,
        finished: data.data[i].finished,
        standard: data.data[i].defaultCampagin,
        runable: runable,
        sent: detail.data.sent,
        viewed: detail.data.viewed,
        pending: detail.data.pending,
        terminated: detail.data.terminated,
      }
      campaigns.push(item)
    }
    setCampaigns(campaigns)
    setcampaignDetail(item)
    loadNFTs()
  }

  function savecampaignDetail(id) {
    setState({ campaignDetailOpen: true })
    setcampaignDetail(campaignTable[id])
  }

  async function addCampagin() {
    setLoading(true)
    let state = true
    // check if mail is mail
    let title = document.getElementById("newCampaignTitle").value
    if(!title) {
      document.getElementById("newCampaignTitleError").classList.remove("hidden")
      state = false
    } else {
      document.getElementById("newCampaignTitleError").classList.add("hidden")
    }

    // check if name is given
    let name = document.getElementById("newClientDescription").value
    if(!name) {
      document.getElementById("newClientDescriptionError").classList.remove("hidden")
      state = false
    } else {
      document.getElementById("newClientDescriptionError").classList.add("hidden")
    }

    let standard = false
    if(document.getElementById("agb").checked) {
      standard = true
    }

    if(state) {
      let url = encodeURI(BACKEND + "campaign/" + title + "/" + name + "/" + standard);
      let check = await axios.post(url)
      if(check.data = "Gespeichert") {
        await loadCampaigns()
        //getLastSavedCampaign()
        loadNFTs()
        handleOpenExistingNFT()
      } else {
        
      }
    }
    setLoading(false)
  }

  async function getLastSavedCampaign() {
    setcampaignDetail(campaignTable[campaignTable.length - 1])
    console.log(campaignTable)
  }

  // function to load linked nfts
  async function loadLinkedNFTs(id) {
    let url = BACKEND + "campaign/nfts/" + campaignTable[id].campid
    let data = await axios.get(url)
    setLinkedNfts(data.data)
  }

  async function loadNFTs() {
    try {
      const response = await axios.get(BACKEND + "nft/agentnfts"); 
      setNfts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  }

  async function editCampagin() {
    handleCloseDetail()
  }

  async function setDefault() {
    if(document.getElementById("agbDetail").checked) {
      let url = BACKEND + "campaign/default/" + campaignDetail.campid
      let check = await axios.post(url)
      loadCampaigns()
    }
  }

  async function setNFT(nft) {
    setLoading(true)
    let url = BACKEND + "campaign/linknft/" + campaignDetail.campid + "/" + nft.contract + "/" + nft.nftId
    try {
      let check = await axios.post(url)
      await loadLinkedNFTs(campaignDetail.id)
      handleCloseExistingNFT()
    } catch(e) {
      console.log(e)
    }
    setLoading(false)
  }

  // save a new nft and link to campaign
  const handleSaveNFT = () => {
    setLoading(true)
    // Make a POST request to save NFT data
    const nftData = {
      nftname,
      description,
      usageDescription,
      websiteUrl,
      validfrom,
      validuntil,
      nftType,
      mintNFT,
      uploadedImage
    };
    const formData = new FormData();
    formData.append("name", nftname)
    formData.append("description", description)
    formData.append("usageDescription", usageDescription)
    formData.append("websiteURL", websiteUrl)
    formData.append("validFrom", validfrom)
    formData.append("validUntil", validuntil)
    formData.append("nftType", nftType)
    formData.append("mintNFT", mintNFT)
    formData.append("image", uploadedImage)
    formData.append("maxSupply", 9999)
    formData.append("maxLoadings", 10)

    // Call the API endpoint and handle the response
    axios.post(BACKEND + "nft/agentnft", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log('NFT saved successfully:', response.data);
        // Optionally, perform any necessary actions after saving the NFT
        // Close the SlidingPane
        loadNFTs()
        handleCloseNewNFT();
      })
      .catch((error) => {
        console.error('Error saving NFT:', error);
        // Handle any errors during the save process
      });
    setLoading(false)
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUsageDescriptionChange = (event) => {
    setUsageDescription(event.target.value);
  };

  const handleWebsiteUrlChange = (event) => {
    setWebsiteUrl(event.target.value);
  };

  const handleValidfromchange = (event) => {
    setValidfrom(event.target.value);
  };

  const handleValiduntilchange = (event) => {
    setValiduntil(event.target.value);
  };
  
  const handleNftTypeChange = (event) => {
    setNftType(event.target.value);
  };

  const handleMaxloadingschange = (event) => {
    setMaxLoadings(event.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setUploadedImage(file);
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const handleAllowPaperNftChange = (event) => {
    setmintNFT(event.target.checked);
  };

  async function runCampaign(id) {
    let url = BACKEND + "campaign/run/" + campaignTable[id].campid
    let check = await axios.post(url)
    loadCampaigns()
  }

  async function campShare(id) {
    let url = BACKEND + "campaign/share/" + campaignTable[id].campid 
    let check = await axios.get(url)
    navigator.clipboard.writeText(check.data);
  } 

  return (
    <div className="App">
      <div className="pageHeader">
        <h2 className="pageTitle">Kampagnen</h2>
        <div className="actionBtns">
          <Button variant="contained" className="" id="" onClick={handleOpenSingle} sx={{ width: 250, margin: "10px"}}>Kampagne erstellen</Button>
        </div>
      </div>
      <div className="contentContainer">
        <div className="pageTable" id="campaignTable">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell align="right">Action</TableCell>
                <TableCell align="right">Link</TableCell>
                <TableCell align="right"># Angesprochen</TableCell>
                <TableCell align="right"># Von Kunde angeschaut</TableCell>
                <TableCell align="right"># Noch offen zur Nutzung</TableCell>
                <TableCell align="right"># Verbraucht</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignTable.map((campaign) => (
                <TableRow
                  key={campaign.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  
                >
                  <TableCell component="th" scope="client" onClick={() => handleOpenDetail(campaign.id)}>
                   {campaign.name}
                  </TableCell>
                  <TableCell align="right">{campaign.default ? 
                    <Tooltip title="Als Standard aktiv. Jeder neue Kunde wird automatisch bearbeitet.">
                      <IconButton >
                        <LoopIcon />
                      </IconButton>
                    </Tooltip> : ( campaign.runable ? (
                      <Tooltip title="Lässt diese Kampagne einmalig laufen.">
                      <IconButton onClick={() => runCampaign(campaign.id)}>
                        <PlayCircleIcon />
                      </IconButton>
                    </Tooltip>
                    ):(
                      <Tooltip title="Eine Kampagne kann nur einmal alle 24 Stunden laufen.">

                        <DoDisturbOnIcon />
                      
                    </Tooltip>
                    ))
                    
                    }
                  </TableCell>
                  <TableCell align="right">
                      <Tooltip title="Erhalte hier den Link für das Teilen der Kampagne">
                      <IconButton onClick={() => campShare(campaign.id)}>
                        <ShareIcon />
                      </IconButton>
                      </Tooltip>
                    
                  </TableCell>
                  <TableCell align="right">{campaign.sent}</TableCell>
                  <TableCell align="right">{campaign.viewed}</TableCell>
                  <TableCell align="right">{campaign.pending}</TableCell>
                  <TableCell align="right">{campaign.terminated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
      </div>
      <Dialog
        open={openSingle}
        onClose={handleCloseSingle}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Neuer Kampagne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''>Erstellen Sie hier eine neue Kampagne</p>
          </DialogContentText>
            <div className='sidediv'>
              <TextField
                id="newCampaignTitle"
                label="Titel"
                placeholder="Meine neue Kampagne"
                margin="dense"
                fullWidth
              />
            </div>
            <p className="errorText hidden" id="newCampaignTitleError">Ungültige Bezeichnung</p>
            <div className='sidediv'>
              <TextField
                id="newClientDescription"
                label="Beschreibung"
                placeholder="Mein Beschreibungstext, der auch Kunden sehen können."
                margin="dense"
                fullWidth
              />
            </div>
            <p className="errorText hidden" id="newClientDescriptionError">Beschreibung ist ungültig.</p>
            <div className='agb'>
                <Checkbox id="agb" className="agbc"/> <p className="agbp">Diese Kampagne als Standard für neu verbundene Kunden nutzen.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSingle}>Abbrechen</Button>
            <LoadingButton loading={loading} onClick={addCampagin}>Speichern</LoadingButton>
            
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Kampagnen Details </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''>Die Details zu Ihrer Kampagne</p>
          </DialogContentText>
            <div className='sidediv'>
              <TextField
                id="newCampaignTitle"
                label="Titel"
                placeholder="Meine neue Kampagne"
                margin="dense"
                fullWidth
                value={campaignDetail.name}
              />
            </div>
            <p className="errorText hidden" id="newCampaignTitleError">Ungültige Bezeichnung</p>
            <div className='sidediv'>
              <TextField
                id="newClientDescription"
                label="Beschreibung"
                placeholder="Mein Beschreibungstext, der auch Kunden sehen können."
                margin="dense"
                fullWidth
                value={campaignDetail.description}
              />
            </div>
            <p className="errorText hidden" id="newClientDescriptionError">Beschreibung ist ungültig.</p>
            <div className='agb'>
                <Checkbox id="agbDetail" onClick={setDefault} className="agbc"/> <p className="agbp">Diese Kampagne wird als Standard für neu verbundene Kunden genutzt.</p>
            </div>
            <div>
              <h3>Verknüpfter NFT</h3>
              {
                linkedNfts.length == 0 ? <p>Kein NFT verknüpft</p> : 
                <Card sx={{ maxWidth: 200 }}>
                    <CardActionArea>
                      <CardMedia

                        component="img"
                        height="140"
                        image={linkedNfts.image}
                        alt={linkedNfts.name}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {linkedNfts.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {linkedNfts.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
              }
            </div>
            <div>
              <LoadingButton loading={loading} onClick={handleOpenExistingNFT}>NFT der Kampagne zuweisen</LoadingButton>
            </div>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetail}>Abbrechen</Button>
            <LoadingButton loading={loading} onClick={editCampagin}>Ok</LoadingButton>
            
        </DialogActions>
      </Dialog>
      <Dialog
        open={openExistingNFT}
        onClose={handleCloseExistingNFT}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Wähle einen NFT für die Kampagne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className=''>Welcher NFT soll der Kampagne zugewiesen werden?</p>
          </DialogContentText>
            <div className='sidediv'>
              <h3>Bereits erstellte NFTs zur Auswahl</h3>
              <div>
              <Grid container spacing={1}>    
              {
                nfts.map((nft) => (
                  <Grid key={nft.nftid} item xs={6} md={2}>
                    <Card sx={{ maxWidth: 200 }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={nft.image} 
                          alt={nft.name}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {nft.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {nft.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <LoadingButton loading={loading} size="small" color="primary" onClick={() => setNFT(nft)}>
                          Diesen NFT der Kampagne zuweisen
                        </LoadingButton>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              )}
              </Grid>
              </div>
              <Button onClick={handleOpenNewNFT}>Neuen NFT erstellen</Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExistingNFT}>Abbrechen</Button>
            <LoadingButton loading={loading} onClick={handleCloseExistingNFT}>Ok</LoadingButton>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={openNewNFT}
        onClose={handleCloseNewNFT}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <DialogTitle>Neuen NFT erstellen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Für eine Erläuterung zur Erstellung von NFTs und deren Typen, beachten Sie bitte die Dokumentationen.
          </DialogContentText>
          <Tabs value={nfttabValue} onChange={handleNftTabChange} indicatorColor="primary">
            <Tab label="Grunddaten" />
            <Tab label="Detaildaten" />
          </Tabs>
          {nfttabValue === 0 && (
            <div>
              <TextField
                label="Name"
                placeholder="Mein neuer NFT"
                value={nftname}
                onChange={handleNameChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Beschreibung"
                placeholder="Öffentlicher Text über meinen NFT"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Nutzungsbeschreibung"
                placeholder="Öffentlicher Text zur Nutzung des NFTs"
                value={usageDescription}
                onChange={handleUsageDescriptionChange}
                fullWidth
                required
                sx={{ m: 1 }}
              />
              <TextField
                label="Website URL"
                placeholder="URL zu Ihrer Kampagnenwebseite"
                value={websiteUrl}
                onChange={handleWebsiteUrlChange}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Gültig ab"
                placeholder="Gültig ab"
                value={validfrom}
                onChange={handleValidfromchange}
                fullWidth
                sx={{ m: 1 }}
              />
              <TextField
                label="Gültig bis"
                placeholder="Gültig bis"
                value={validuntil}
                onChange={handleValiduntilchange}
                fullWidth
                sx={{ m: 1 }}
              />
              <FormControl fullWidth sx={{ m: 1 }}> 
                <Select value={nftType} onChange={handleNftTypeChange}>
                  <MenuItem value="Gutscheinartig">Gutscheinartig</MenuItem>
                  <MenuItem value="Stempelkarte">Stempelkarte</MenuItem>
                </Select>
              </FormControl>
              {nftType==="Stempelkarte" && (
                <TextField
                  label="Maximale Anzahl Stempel"
                  placeholder="10"
                  value={maxLoadings}
                  onChange={handleMaxloadingschange}
                  fullWidth
                  sx={{ m: 1 }}
                />
              )}
            </div>
          )}
          {nfttabValue === 1 && (
            <div>
              <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageUpload}
                  sx={{ m: 1 }}
                />
                <label htmlFor="image-upload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<AddPhotoAlternate />}
                  >
                    Upload Image
                  </Button>
                </label>
                {uploadedImage && (
                  <div>
                    <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded NFT" style={{ width: '100px', height: '100px' }} />
                    <IconButton onClick={handleDeleteImage}>
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mintNFT}
                    onChange={handleAllowPaperNftChange}
                  />
                }
                label="NFT auf Blockchain speichern"
                sx={{ m: 1 }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {nfttabValue === 1 && (
            <Button onClick={() => setNftTabValue(0)}>Zurück</Button>
          )}
          <Button onClick={handleCloseNewNFT}>Abbrechen</Button>
          {nfttabValue === 0 && (
            <Button onClick={() => setNftTabValue(1)}>Weiter</Button>
          )}
          {nfttabValue === 1 && (
            <LoadingButton loading={loading} onClick={handleSaveNFT}>Hinzufügen</LoadingButton>
          )}
        </DialogActions>
      </Dialog>
     
    </div>
  );
}

export default Campaigns;
