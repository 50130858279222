import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import {
  BACKEND
} from '../config.js'


import {  
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid
} from '@mui/material';

function Mynfts() {

  const [nfts, setNfts] = useState([]);
  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
  }, [])

  useEffect(() => {
    getNFTs();
}, [])

  const getNFTs = async () => {
    try {
      const response = await axios.get(BACKEND + "nft/clientnfts");
      setNfts(response.data);
      console.log(response.data)
      console.log(nfts)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }  




  return (
    <div className="App">
      <h2>Übersicht über deine aktiven Connectly Token</h2>
      <div className="nftlist">
      <Grid container spacing={2}>
      { nfts.length > 0 && (
        nfts.map((nft, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <a href={"/nft/" + nft.contract + "/" + nft.token_id} style={{textDecoration: 'none', color: 'black'}}>
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea>
              <CardMedia

                component="img"
                maxHeight="400px"
                image={nft.image}
                alt={nft.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {nft.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {nft.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          </a>
          </Grid>
        ))
      )
    }
    </Grid>
    </div>
    </div>
  );
}

export default Mynfts;
