import logo from '../logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import {
  BACKEND
} from '../config.js'


import {  
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

function Agents() {

  const [agents, setAgents] = useState([]);
  const storedJwt = localStorage.getItem('connectlyToken');
  const [jwt] = useState(storedJwt || null);
  const [loading, setLoading] = useState(false);
  // check if jwt token is available else redirect to app.js
  useEffect(() => {
    if(!jwt) {
      window.location.href = "/";
    }
  }, [])

  useEffect(() => {
    getAgents();
}, [])

  const getAgents = async () => {
    try {
      const response = await axios.get(BACKEND + "agent/allagents");
      const myagents = await axios.get(BACKEND + "agent/myagents");
      console.log(response.data)
      for(let i = 0; i < response.data.length; i++) {
        for(let j = 0; j < myagents.data.length; j++) {
          if(response.data[i].name == myagents.data[j].name) {
            response.data.splice(i, 1)
          }
        }
      }

      setAgents(response.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }  

  async function connect(hash) {
    setLoading(true)
    try {
        let url = BACKEND + "client/setconbymail/" + hash
        let connect = await axios.post(url)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    await getAgents()
    setLoading(false)
  }


  return (
    <div className="App">
      <h2>Finde hier alle aktiven Partnerfirmen von Connectly</h2>
      <p>Registriere dich direkt bei einer Partnerfirma, das dein Interesse weckt.</p>
      <div className="nftlist">
      <Grid container spacing={2}>
      { agents.length > 0 && (
        agents.map((agent, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea>
              <CardMedia

                component="img"
                maxHeight="400px"
                image={agent.logourl}
                alt={agent.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {agent.name}
                </Typography>
                <LoadingButton loading={loading} onClick={() => connect(agent.hash)}>Abonnieren</LoadingButton>
              </CardContent>
            </CardActionArea>
          </Card>
          </Grid>
        ))
      )
    }
    {
        agents.length == 0 && (
            <div>
                <h2>Keine Partnerfirmen gefunden</h2>
                <p>Es wurden keine Partnerfirmen gefunden, die du noch nicht abonniert hast.</p>
            </div>
            )
    }
    </Grid>
    </div>
    </div>
  );
}

export default Agents;
