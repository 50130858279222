import './App.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import axios from 'axios'
import QRCode from "react-qr-code";

import {
  BACKEND
} from './config.js'

import {  
  Typography,
  TextField,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Tooltip,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

function Nft() {

    const { contract, nftid } = useParams();
    const [nft, setNft] = useState({});
    const [agent, setAgent] = useState(false);
    const [client, setClient] = useState(false);
    const [type, setType] = useState(0);
    const [openQR, setOpenQR] = useState(false);
    const [link, setLink] = useState(window.location.href);
    const [disabled, setDisabled] = useState(false);
    const [wallet, setWallet] = useState("");

    const [stampLoading, setStampLoading] = useState(false);
 
  useEffect(() => {
    getNFT();
}, [])

const handleCloseQR = () => {
  setOpenQR(false);
};

const handleOpenQR = () => {
  setOpenQR(true);
};

  const getNFT = async () => {
    try {
      const response = await axios.get(BACKEND + "nft/" + contract + "/" + nftid);
      setNft(response.data);
      // check if user is owner
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      )
      const signer = provider.getSigner()
      await provider.send("eth_requestAccounts", [])
      const address = await signer.getAddress() 

      if(address == response.data.granter) {
        setAgent(true)
        setWallet(address)
      }

      // check if valid
      if(response.data.validity == "false") {
        setDisabled(true)
      }

      // check if user is owning client

      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  } 

  async function stamp() {
    if(agent) {
      setStampLoading(true)
      console.log("ask for signature")
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      )
      console.log("connected")
      const signer = provider.getSigner()
      let messageToSign = "Connectly Token Stempel: " + nft.title + " (" + nftid + ") wirklich stempeln?"
      let signedMessage = false
      try {
        signedMessage = await signer.signMessage(messageToSign)
      } catch(e) {
        setStampLoading(false)
        return
      }
      
      const response = await axios.post(BACKEND + "nft/use/" + contract + "/" + nftid)
      getNFT()
      setStampLoading(false)
    }
  }

  async function blockchainTransfer() {

  }

  async function deleteNFT() {

  }

  return (
    <div className="App">
    { nft.attributes != undefined && (
        <div className="nftcontainer">
            {
              disabled && (<h4 className="left">Dieser Gutschein ist nicht gültig.</h4>)
            }
            <Grid container spacing={2}>
              <Grid className="nftImgGrid" div xs={11} md={7}>
                  <div className="nftbuttons">
                      {
                        (agent == true &&
                          <LoadingButton style={{width: '100%', height: '50px', margin: '20px'}} disabled={disabled} size="large" loading={stampLoading} variant="contained" color="success" onClick={stamp} target="_blank">Stempeln</LoadingButton >
                        )
                      }
                      
                  </div>
                  <div className="nft-left">
                      <h2 className="">{nft.title}</h2>
                      <img src={nft.image} className="nftimg"/>
                      
                  </div>
                  
                </Grid>
                <Grid div xs={12} md={5}>
                  <div className="nftbuttonsbot">
                      {
                        (agent == false &&
                          <div>
                            <Button style={{width: '150px', height: '100px', margin: '10px'}} disabled={disabled} size="large" variant="contained" color="primary" onClick={handleOpenQR} target="_blank">QR-Code zeigen</Button>
                            <LoadingButton style={{width: '150px', height: '100px', margin: '10px'}} disabled={true} size="large" loading={stampLoading} variant="contained" color="secondary" onClick={blockchainTransfer} target="_blank">Blockchain Transfer</LoadingButton >
                            <LoadingButton style={{width: '150px', height: '100px', margin: '10px'}} disabled={true} size="large" loading={stampLoading} variant="contained" color="error" onClick={deleteNFT} target="_blank">Löschen</LoadingButton >
                          </div>
                        )
                      }
                  </div>
                  <div className="nft-right">
                      <div className="descblock">
                        <h4 className="left">Beschreibung:</h4>
                        <p className="desc">{nft.description}</p>
                      </div>
                      <div className="descblock">
                        <h4 className="left">Nutzungsbeschrieb:</h4>
                        <p className="desc">{nft.attributes[4].value}</p>
                      </div>
                  </div>
                </Grid>
            </Grid>
            <TableContainer className="nfttable" component={Paper} style={{ width: "100%" }}>
                        <Table>
                          <TableBody>
                              <TableRow>
                                  <TableCell className="nftlabel">Typ</TableCell>
                                  <TableCell className="nftvalue">{nft.attributes[0].value}</TableCell>
                              </TableRow>
                            
                              {
                                  (nft.external_url != undefined &&
                                      <TableRow>
                                          <TableCell className="nftlabel">Weitere Informationen</TableCell>
                                          <TableCell className="nftvalue"><a href={"https://" + nft.external_url} target='_blank'>Webseite des Anbieters</a></TableCell>
                                      </TableRow>
                                  )
                              }
                              <TableRow>
                                  <TableCell className="nftlabel">Wie häufig nutzbar</TableCell>
                                  <TableCell className="nftvalue">{nft.attributes[2].value}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell className="nftlabel">Wie häufig bereits genutzt</TableCell>
                                  <TableCell className="nftvalue">{nft.attributes[3].value}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell className="nftlabel">Herausgeber</TableCell>
                                  <TableCell className="nftvalue">{nft.grantername}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell className="nftlabel">Gültig ab</TableCell>
                                  <TableCell className="nftvalue">{nft.validfrom}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell className="nftlabel">Gültig bis</TableCell>
                                  <TableCell className="nftvalue">{nft.validuntil}</TableCell>
                              </TableRow>
                          </TableBody>
                          </Table>
                      </TableContainer>
        </div>
        )}
        <Dialog
        open={openQR}
        onClose={handleCloseQR}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        

        >
          <DialogTitle className="qrdiv">Dein QR-Code</DialogTitle>
          <DialogContent>
            <DialogContentText className="qrdiv">
              <p className='qrdiv'>Zeige diesen QR dem Verkäufer zur Bestätigung.</p>
              <QRCode className="qrdiv" value={link} />
            </DialogContentText>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseQR}>Schliessen</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

export default Nft;
