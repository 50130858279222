import '../App.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import axios from 'axios'
import logo from '../assets/img/LogoReverse.png'
import { LoadingButton } from '@mui/lab';

import {
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tab,
    Tabs,
    Checkbox
  } from '@mui/material';

  import {
    BACKEND
  } from '../config.js'

  import card from '../assets/img/card.png'
  import metamask from '../assets/img/MetaMask.png'


function Connect() {

    const [open, setOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [mail, setMail] = useState("");
    const [wallet, setWallet] = useState("");
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [go, setGo] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [logintabValue, setLoginTabValue] = useState(0);
    const { hash } = useParams();
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);
    const [loading, setLoading] = useState(false);
    const [alogo, setLogo] = useState(null);

    const storedJwt = localStorage.getItem('connectlyToken');
    const [jwt, setJwt] = useState(storedJwt || null);

    // check if jwt token is available
    useEffect(() => {
        checkToken()
      }, [])
    
    useEffect(() => {
        getAgent();
    }, [])
    

    async function checkToken() {
        if(jwt) {
          //let tokenURL = BACKEND + "login/verify/" + jwt
          //let token = await axios.get(tokenURL)
          let token = null
          token = localStorage.getItem('connectlyToken');
          if(token != null) {

            if(localStorage.getItem("type") == "2"){
              await connectClient()
              window.location.href = "/mynfts";
            }
            if(localStorage.getItem("type") == "1") {
              //window.location.href = "/agentdashboard";
            }
          } else {
            localStorage.removeItem('connectlyToken');
            localStorage.removeItem('type');
            setJwt(null);
          }
          
        }
        // load logo of agent if hash is present
        console.log(hash)
        if(hash) {
            let url = BACKEND + "agent/getlogo/" + hash
            let logo = await axios.get(url)

            if(logo) {
                setLogo(BACKEND + logo.data)
            }
        }
      }

    const handleMailChange = (event) => {
        setMail(event.target.value);
      };

    const handleWalletchange = (event) => {
        setWallet(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
        setDisabled2(false)
    };

    async function handleTabChange(tab) {
        if(tab == 1) {
            setLoading(true)
            let mailok = await checkMail()
            setLoading(false)
            if("sent") {
                setTabValue(tab);
            }
        }
        if(tab == 2) {
            setLoading(true)
            let codeok = await sendCode()
            if(codeok) {
                console.log(hash)
                if(hash) {
                    let save = await connectClient()
                }
            }
            setLoading(false)
            localStorage.setItem('type', 2);
            window.location.href = "/mynfts";
        }
        
        
    };

    async function handleLoginTabChange(tab) {
        if(tab == 1) {
            setLoading(true)
            let mailok = await checkMailLogin()
            setLoading(false)
            if("sent") {
                setLoginTabValue(tab);
            }
        }
        if(tab == 2) {
            setLoading(true)
            let codeok = await sendLoginCode()
            setLoading(false)
            if(codeok) {
                localStorage.setItem('type', 2);
                if(hash != "connect" && hash != undefined) {
                    let save = await connectClient()
                    if(save) {
                        window.location.href = "/mynfts";
                    }
                } else {
                    window.location.href = "/mynfts";
                }
                
            }
        }
        
    };

    const getAgent = async () => {
        // check if hash is valid
        let checkHash = await axios.get(BACKEND + "hash/verify/" + hash)

        try {
            let agent = checkHash.data[0].id
        } catch(e) {
            setGo(false)
        }

    }

    async function checkMail() {
        if(mail == "" || !mail.includes("@")) {
          return false
        } else {
          let url = BACKEND + "register/mail/" + mail
          let check = await axios.post(url)
        }
        
      }

    async function checkMailLogin() {
    if(mail == "" || !mail.includes("@")) {
        return false
    } else {
        let url = BACKEND + "login/mail/" + mail
        let check = await axios.post(url)
    }
    
    }

    async function sendLoginCode() {
        if(code == "") {
            return false
          } else {
              // Call the API endpoint and handle the response
              let check = await axios.get(BACKEND + "login/mail/" + mail + "/" + code)
              if(check.data) {
                  localStorage.setItem('connectlyToken', check.data);
                  return true
              } else {
                  return false
                  
              }
          }
    }

    async function sendCode() {
        if(code == "") {
          return false
        } else {
            const formData = new FormData();
            formData.append("name", name)
            formData.append("mail", mail)
            formData.append("wallet", wallet)
            formData.append("code", code)
            // Call the API endpoint and handle the response
            let check = await axios.get(BACKEND + "register/code/" + mail + "/" + code)
            if(check.data) {
                localStorage.setItem('connectlyToken', check.data);
                return true
            } else {
                return false
                
            }
        }
      }

      async function connectClient() {
        let url = BACKEND + "client/setconbymail/" + hash
        let connect = await axios.post(url)
        return(connect)
      }

      function switchAgb() {
        if(document.getElementById("agb").checked) {
            setDisabled(false)
        } else {
            document.getElementById("contBtn").classList.add("Mui-disabled")
            setDisabled(true)
        }
      }

  return (
    <div className="App">
        <div className="" id="start">
            <h2 className="mainTitle">Willkommen bei <span className="logotext">connectly</span></h2>
            <p className="subTitleExplanation">Anmelden oder registrieren?</p>
            <div className="">
            <img src={card} className=""></img>
            </div>
            <Button variant="contained" className="" id="connectBtn" onClick={() => setLoginOpen(true)} sx={{ width: 250, }}>Anmelden</Button>
            <div className="or">
            <p className="lineg"><span>Oder</span></p>
            </div>
            <Button className="" id="" onClick={() => setOpen(true)} sx={{ width: 250, color: "white", background: "black"}}>Jetzt registrieren</Button>
            <p className="" id="message"></p>
            <p className="" id="signed"></p>
            <p className="" id="result"></p>
            <p className="">Als Partnerunternehmen <a href="/login">anmelden?</a></p>
      </div>
        <Dialog
            fullScreen
            open={loginOpen}
            onClose={() => setLoginOpen(false)}

        >
            <div className="fullscreendialog">
                <div className="dialogleft">
                    <img src={logo} />
                </div>
                <div className="dialogright">
                    <div className="regForm">
                        <h3>Anmeldung</h3>
                        <p>Melde dich mit deiner E-Mail Adresse an</p>
                        {logintabValue === 0 && (
                            <div>
                                <TextField
                                label="Meine E-Mail"
                                value={mail}
                                onChange={handleMailChange}
                                fullWidth
                                required
                                ></TextField>
                                <LoadingButton loading={loading} sx={{ width: "100%", color: "white", background: "black"}} id="" onClick={() => handleLoginTabChange(1)}>Weiter</LoadingButton>
                            </div>
                        )}
                        {logintabValue === 1 && (
                            <div>
                                <TextField
                                label="Bestätigungscode"
                                placeholder="z.B. 1234"
                                onChange={handleCodeChange}
                                fullWidth
                                required
                                ></TextField>
                                <LoadingButton loading={loading} sx={{ width: "100%", color: "white", background: "black"}} id="" onClick={() => handleLoginTabChange(2)}>Bestätigen</LoadingButton>
                            </div>
                        )
                        }
                        { hash != null && (
                            <div>
                                <p>Verbindung mit:</p>
                                <img src={alogo} className='agentLogo'></img>
                            </div>
                        )}
                        <Button onClick={() => setLoginOpen(false)}>Abbrechen</Button>
                </div>
                </div>
            </div>
        </Dialog>
        <Dialog 
            fullScreen
            open={open}
        >
            <div className="fullscreendialog">
                <div className="dialogleft">
                    <img src={logo} className=""></img>
                </div>
                    <div className="dialogright">
                        <div className="regForm">
                            <h3>Registrierung</h3>
                            {tabValue === 0 && (
                                <div>
                                    <p>Registriere dich mit deiner E-Mail Adresse</p>
                                    <TextField
                                        label="Meine E-Mail"
                                        placeholder="E-Mail meiner Firma"
                                        value={mail}
                                        onChange={handleMailChange}
                                        fullWidth
                                        required
                                        
                                    />
                                    <TextField
                                        label="Meine Web3 Wallet"
                                        placeholder="0x00..."
                                        value={wallet}
                                        onChange={handleWalletchange}
                                        fullWidth
                                        
                                    />
                                    <TextField
                                        label="Deine Name"
                                        placeholder="z.B. Hans Muster"
                                        value={name}
                                        onChange={handleNameChange}
                                        fullWidth
                                        
                                    />
                                    <div className='agb'>
                                        <Checkbox id="agb" onClick={switchAgb} className="agbc"/> <p className="agbp">Ich habe die <a href="/terms" target="_blank">Nutzungsbedingungen</a> und die <a href="dataprotection" target="_blank">Datenschutzerklärung</a> gelesen und bin damit einverstanden.</p>
                                    </div>
                                    <LoadingButton loading={loading} disabled={disabled} sx={{ width: "100%"}} contained id="contBtn" onClick={() => handleTabChange(1)}>Weiter</LoadingButton>
                                </div>
                            )}
                            {tabValue === 1 && (
                                <div>
                                    <p>Bestätige deine E-Mail Adresse</p>
                                    <TextField
                                        label="Bestätigungscode"
                                        placeholder="z.B. 1234"
                                        onChange={handleCodeChange}
                                        fullWidth
                                        required
                                        
                                    />
                                    <LoadingButton disabled={disabled2} loading={loading} sx={{ width: "100%", color: "white", background: "black"}} id="" onClick={() => handleTabChange(2)}>Weiter</LoadingButton>
                                </div>
                            )}
                        </div>
                        { hash != null && (
                            <div>
                                <p>Verbindung mit:</p>
                                <img src={alogo} className='agentLogo'></img>
                            </div>
                        )}
                        <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                    </div>
            </div>
        </Dialog>
    </div>
  );
}

export default Connect;
