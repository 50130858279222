import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios'

import {
  backend
} from './config.js'



function Dataprotection() {

  

  return (
    <div className="App">
      <h2>Datenschutzvereinbarung</h2>
    </div>
  );
}

export default Dataprotection;
